import * as React from "react"
import Seo from "../components/seo"
import Compliance from "../components/sections/compliance-policy/CompliancePolicy"
import { Helmet } from "react-helmet"

const CompliancePolicyPage = () => (
  <>
    <Seo title="Compliance Policy" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Compliance/>
  </>
)

export default CompliancePolicyPage
